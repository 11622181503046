/*
 * Investment made for renewable energy in germany
 * according to different factors for power and gas, consumption and years
 * */

import calculateSumOfAllContractDurationsInYears from "./calculateSumOfAllContractDurationsInYears";
import getTotalConsumption from "./getTotalConsumption";
import {
  gasRecommendationConsumption,
  powerRecommendationConsumption,
} from "./recommendationSetup";
import type { Contract } from "~/src/generated-sources/public";
import type { AlternativeImpactContract } from "~/types/shared/impact-calculator";

/**
 * Calculate the investment made for renewable energy in germany by Polarstern.
 * @param contracts The contracts to calculate the investment for.
 * @param recommendationCount The amount of recommendations of the customer.
 * @returns The investment made for renewable energy in germany by Polarstern in rounded Euro.
 */
export default function (
  contracts: (Contract | AlternativeImpactContract)[],
  recommendationCount: number,
) {
  const yearlyInvestment = 20;
  const factorPower = 0.01; // Euro pro kWh
  const factorGas = 0.0025; // Euro pro kWh

  const gasContracts = contracts.filter((c) => c.type === "gas");
  const powerContracts = contracts.filter((c) => c.type === "power");

  const totalGasConsumption =
    getTotalConsumption(gasContracts) +
    gasRecommendationConsumption * recommendationCount;

  const totalPowerConsumption =
    getTotalConsumption(powerContracts) +
    powerRecommendationConsumption * recommendationCount;

  const allContractsDuration = calculateSumOfAllContractDurationsInYears(contracts);

  return Math.round(
    yearlyInvestment * allContractsDuration +
      (totalPowerConsumption * factorPower + totalGasConsumption * factorGas),
  );
}
