import calculateContractRunTimeInYears from "./calculateContractRunTimeInYears";
import type { Contract } from "~/src/generated-sources/public";
import type { AlternativeImpactContract } from "~/types/shared/impact-calculator";

/**
 * Calculate the sum of all contract durations in years (not rounded).
 * @param contracts The contracts to calculate the sum of all contract durations for.
 * @returns The sum of all contract durations in years.
 */
export default function (contracts: (Contract | AlternativeImpactContract)[]): number {
  return contracts
    .map((contract) => calculateContractRunTimeInYears(contract))
    .reduce((acc, contractDuration) => acc + contractDuration, 0);
}
